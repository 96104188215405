<script>
    /***
     Name: tree
     Code: sa0ChunLuyu
     Time: 2021/11/3 16:39
     Remark: 树组件
     */
    import Tree from '../../../components/sa0/atree/atree'

    const treeData = [
        {
            title: '全部',
            key: '0-0',
            type: 0,
            children: [
                {
                    title: '分类第一',
                    key: '0-0-0',
                    content: {
                        pid: '1',
                        b: '2',
                    },
                    type: 0,
                    children: [
                        {
                            title: '分类一的孩子', type: 0,
                            content: {
                                a: '1',
                                b: '2',
                            }, key: '0-0-0-0'
                        },
                        {
                            title: '分类一的孩子', type: 0,
                            content: {
                                a: '1',
                                b: '2',
                            }, key: '0-0-0-1',
                            children: [
                                {
                                    title: '分类二的孩子', type: 1,
                                    content: {
                                        a: '1',
                                        b: '2',
                                    }, key: '0-0-1-0'
                                },
                                {
                                    title: '分类二的孩子', type: 1,
                                    content: {
                                        a: '1',
                                        b: '2',
                                    }, key: '0-0-1-1'
                                },
                                {
                                    title: '分类二的孩子', type: 1,
                                    content: {
                                        a: '1',
                                        b: '2',
                                    }, key: '0-0-1-2'
                                },
                            ],
                        },
                        {
                            title: '分类一的孩子', type: 0,
                            content: {
                                a: '1',
                                b: '2',
                            }, key: '0-0-0-2',
                            children: [
                                {
                                    title: '分类二的孩子', type: 1,
                                    content: {
                                        a: '1',
                                        b: '2',
                                    }, key: '0-0-1-0'
                                },
                                {
                                    title: '分类二的孩子', type: 1,
                                    content: {
                                        a: '1',
                                        b: '2',
                                    }, key: '0-0-1-1'
                                },
                                {
                                    title: '分类二的孩子', type: 1,
                                    content: {
                                        a: '1',
                                        b: '2',
                                    }, key: '0-0-1-2'
                                },
                            ],
                        },
                    ],
                },
                {
                    title: '分类二',
                    key: '0-0-1',
                    type: 0,
                    content: {
                        a: '1',
                        b: '2',
                    },
                    children: [
                        {
                            title: '分类二的孩子', type: 1,
                            content: {
                                a: '1',
                                b: '2',
                            }, key: '0-0-1-0'
                        },
                        {
                            title: '分类二的孩子', type: 1,
                            content: {
                                a: '1',
                                b: '2',
                            }, key: '0-0-1-1'
                        },
                        {
                            title: '分类二的孩子', type: 1,
                            content: {
                                a: '1',
                                b: '2',
                            }, key: '0-0-1-2'
                        },
                    ],
                },
            ],
        },
    ];
    const dataMaker = (data) => {
        return data.map((item) => {
            let ii = {
                title: item.title,
                key: item.key,
                type: item.type,
                content: item.content,
            }
            ii.child_type = -1
            if (typeof item.children !== 'undefined') {
                ii.children = dataMaker(item.children)
                if (item.children.length) {
                    ii.child_type = item.children[0].type
                }
            }
            return ii
        })
    }
    export default {
        components: {Tree},
        data() {
            return {
                tree_show: false,
                treeData: dataMaker(treeData),
                expandedKeys: ['0-0-0', '0-0-1'],
                menu: {
                    dir_type: 0,
                    item_type: 1,
                    dir: [
                        {
                            title: '新建',
                            only: -1,
                            children: [{
                                title: '新建文件夹',
                                only: 1,
                                func: (item) => {
                                    // 新建接口
                                    console.log('按钮11', item)
                                    this.$refs['treeref'].renameClick('0-0-1-2', '0-0-1-2')
                                }
                            }, {
                                title: '新建内容',
                                only: 0,
                                func: (item) => {
                                    console.log('按钮12', item)
                                }
                            }]
                        },
                        {
                            title: '删除',
                            only: 1,
                            func: (item) => {
                                console.log('按钮2', item)
                            }
                        },
                        // {
                        //     title: '新建文件夹',
                        //     only: 0,
                        //     func: (item) => {
                        //         console.log('按钮3', item)
                        //     }
                        // }, {
                        //     title: '长显示按钮',
                        //     only: -1,
                        //     func: (item) => {
                        //         console.log('按钮3', item)
                        //     }
                        // }
                        //
                    ],
                    item: [
                        {
                            title: '操作',
                            only: -1,
                            children: [{
                                title: '上移',
                                only: -1,
                                func: (item) => {
                                    console.log('按钮11', item)
                                }
                            }, {
                                title: '下移',
                                only: -1,
                                func: (item) => {
                                    console.log('按钮12', item)
                                }
                            }]
                        },
                        {
                            title: '删除',
                            only: -1,
                        },


                    ]
                },
            }
        },
        mounted() {
            setTimeout(() => {
                this.tree_show = true
            })
        },
        methods: {
            renameFunc(item, name) {
                console.log(item, name)
            }
        }
    }
</script>
<template>
    <div class="menutree">
        <div class="menutitle">项目分类列表</div>
        <div v-if="tree_show">
            <Tree show-line ref="treeref" :menu="menu" :renameFunc="renameFunc" :rename="true" :treeData="treeData" :expandedKeys="expandedKeys" class="treemain"></Tree>
        </div>
    </div>
</template>
<style scoped>
    .menutree{margin-left: -20px; width: 200px; border: 1px solid #eeeeee; height: calc(100vh - 120px); display: flex; flex-direction: column; font-size: 12px}

    .menutitle{ width: 200px; background: #f9f9f9; line-height: 36px; padding-left: 10px; font-size: 14px; font-weight: bold}

    .treemain{ width: 100%; flex: 1; overflow-y: auto; font-size: 12px}

    .treemain::-webkit-scrollbar{
        /*滚动条整体样式*/
        width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
        }

    .treemain::-webkit-scrollbar-thumb{
        /*滚动条里面小方块*/
        border-radius: 4px;
        background: #ccc;
        }

    .treemain::-webkit-scrollbar-track{
        /*滚动条里面轨道*/
        border-radius: 4px;
        background: #f9f9f9;
        }
</style>
