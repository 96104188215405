<template>
    <div class="treemain">
        <a-tree :tree-data="treeData" @select="onSelect" :expanded-keys.sync="expandedKeyss" :autoExpandParent="true">
            <template #title="{ key: treeKey, title, type,content,child_type }">
                <div @dblclick="renameClick(treeKey,title)">
                    <div v-if="rename_active !== treeKey" class="touming"></div>
                    <div v-if="rename_active !== treeKey" class="treetit">
                        <a-dropdown :trigger="['contextmenu','click']">
                            <div style="position:absolute;right: 0;">
                                <a-icon v-if="active_key === treeKey" type="more" class="treemore"/>
                            </div>
                            <template #overlay>
                                <a-menu>
                                    <a-menu-item @click="itemClick(menu_item,{ key: treeKey, title, type,content })" v-if="type === menu.dir_type && ( (menu_item.only === -1 || menu_item.only === child_type) || child_type === -1)" :key="menu_key" v-for="(menu_item,menu_key) in menu.dir">
                                        <div v-if="!menu_item.children">{{ menu_item.title }}</div>
                                        <a-dropdown v-else :trigger="['click','hover']" placement="topLeft">
                                            <div style="width: 100px">
                                                {{ menu_item.title }}
                                                <span style="float: right; vertical-align: middle; margin-top: -2px"><a-icon type="caret-right"/></span>
                                            </div>
                                            <template #overlay>
                                                <a-menu style="position:absolute;transform: translateX(calc(100% + 12px));right: 0;">
                                                    <a-menu-item v-if="(menu_children_item.only === -1 || menu_children_item.only === child_type) || child_type === -1" :key="menu_children_key" @click="itemClick(menu_children_item,{ key: treeKey, title, type,content })" v-for="(menu_children_item,menu_children_key) in menu_item.children">
                                                        {{ menu_children_item.title }}
                                                    </a-menu-item>
                                                </a-menu>
                                            </template>
                                        </a-dropdown>
                                    </a-menu-item>
                                    <a-menu-item @click="itemClick(menu_item,{ key: treeKey, title, type,content })" v-if="type === menu.item_type" :key="menu_key" v-for="(menu_item,menu_key) in menu.item">
                                        <div v-if="!menu_item.children">{{ menu_item.title }}</div>
                                        <a-dropdown v-else :trigger="['click','hover']" placement="topLeft">
                                            <div style="width: 100px">{{ menu_item.title }}

                                                <span style="float: right; vertical-align: middle; margin-top: -2px"><a-icon type="caret-right"/></span>
                                            </div>
                                            <template #overlay>
                                                <a-menu style="position:absolute;transform: translateX(calc(100% + 12px));right: 0;">
                                                    <a-menu-item @click="itemClick(menu_children_item,{ key: treeKey, title, type,content })" :key="menu_children_key" v-for="(menu_children_item,menu_children_key) in menu_item.children">
                                                        {{ menu_children_item.title }}
                                                    </a-menu-item>
                                                </a-menu>
                                            </template>
                                        </a-dropdown>
                                    </a-menu-item>
                                </a-menu>
                            </template>
                        </a-dropdown>
                        <div class="navname">
                            <a-icon v-if="type===0" type="folder" style="vertical-align: middle"/>
                            <a-icon v-else type="file" style="vertical-align: middle"/>
                            {{ title }}
                        </div>
                    </div>
                    <div v-show="rename_active === treeKey">
                        <a-input v-model="rename_input" :ref="`input-${treeKey}`" @blur="renameBlur({ key: treeKey, title, type,content })"></a-input>
                    </div>
                </div>
            </template>
        </a-tree>
    </div>
</template>

<script>
    export default {
        name: "atree",
        props: {
            treeData: {
                type: Array,
                default: [],
            },
            expandedKeys: {
                type: Array,
                default: []
            },
            menu: {
                type: Object,
                default: {
                    dir_type: 0,
                    item_type: 1,
                    dir: [],
                    item: [],
                }
            },
            rename: {
                type: Boolean,
                default: false
            },
            renameFunc: {
                type: Function,
                default: () => {
                }
            }
        },
        mounted() {
            this.expandedKeyss = JSON.parse(JSON.stringify(this.expandedKeys))
        },
        data() {
            return {
                rename_input: '',
                rename_active: '',
                active_key: '',
                expandedKeyss: []
            }
        },
        methods: {
            onSelect(e) {
                if (e.length) {
                    this.active_key = e.pop()
                }
            },
            renameBlur(item) {
                if (this.rename) {
                    this.renameFunc(item, this.rename_input);
                }
                this.rename_input = '';
                this.rename_active = '';
            },
            renameClick(treeKey, title) {
                if (this.rename) {
                    this.rename_input = title;
                    this.rename_active = treeKey;
                    setTimeout(() => {
                        this.$refs[`input-${treeKey}`].focus()
                    })
                }
            },
            itemClick(menu, item) {
                if (typeof menu.func !== 'undefined') menu.func(item)
            },
        },
    }
</script>
<style>
    .ant-tree-node-selected::before{
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        height: 36px;
        background: #e6f7ff;
        z-index: -1;
        margin-top: -6px;
        }

    .ant-tree{ font-size: 14px !important}

    .ant-tree li .ant-tree-node-content-wrapper:hover ::before{
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        height: 36px;
        background: #e6f7ff;
        z-index: -1;
        margin-top: -6px;
        }

    .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected{
        background-color: #e6f7ff !important; font-size: 14px !important
        }

    .ant-tree li ul{
        margin: 0;
        padding: 0 0 0 10px;
        }

    .ant-tree li span.ant-tree-node-content-wrapper{
        margin-left: -5px;
        }

    .ant-tree li span.ant-tree-switcher{
        width: 18px !important;
        }

</style>

<style scoped>
    .treemain{ width: 200px; position: relative}

    .navname{ max-width: 160px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;}

    .touming{ width: 60%; background: rgba(255, 255, 255, 0); position: absolute; right: 0; height: 30px; }

    .ant-dropdown{ right: 0px !important; background: #0C0C0C !important}

    .treemore{ transform: rotate(-90deg); font-weight: bold; position: absolute; right: 10px; top: 3px; font-size: 20px}

    .ant-dropdown{ right: 0px !important; background: #0C0C0C !important}

    .ant-dropdown-menu{ background: rgba(0, 0, 0, 0.8); color: #ffffff}

    .ant-dropdown-menu-item, .ant-dropdown-menu-submenu-title{ color: #ffffff}

    .ant-dropdown-menu-item:hover{ background: #666}
</style>
